<template>
  <button
    v-if="isExperimentActive"
    class="rounded-md flex items-center p-8 duration-200 motion-safe:transition-all"
    :class="[
      {
        'text-static-default-hi cursor-pointer lg:hover:!bg-static-info-mid-hover lg:group-hover:bg-static-info-mid lg:bg-transparent':
          !isComparisonAddDisabled || isChecked,
        'text-static-default-hi-disabled cursor-not-allowed lg:bg-static-info-mid-disabled':
          isComparisonAddDisabled && !isChecked,
      },
      isResponsiveComparisonEnabled
        ? 'visible opacity-100'
        : 'invisible opacity-0',
      isChecked
        ? 'lg:visible lg:opacity-100'
        : 'lg:invisible lg:opacity-0 lg:group-hover:visible lg:group-hover:opacity-100',
    ]"
    :disabled="isComparisonAddDisabled && !isChecked"
    @click.stop.prevent="handleClick"
  >
    <span
      class="body-2-bold mx-8 hidden overflow-hidden whitespace-nowrap group-hover:![transition-delay:50ms] motion-safe:[transition:max-width_300ms_ease-in-out,opacity_100ms] lg:inline lg:max-w-[0px] lg:opacity-0 lg:group-hover:max-w-[400px] lg:group-hover:opacity-100"
      :class="[
        isResponsiveComparisonEnabled
          ? 'max-w-[400px] opacity-100'
          : 'max-w-[0px] opacity-0',
      ]"
    >
      {{ i18n(translations[isChecked ? 'clear' : 'compare']) }}
    </span>
    <IconCheckInCircleFilled v-if="isChecked" size="32" />
    <IconCircle v-else size="32" />
  </button>
</template>

<script lang="ts" setup>
import { useExperiments } from '@backmarket/nuxt-module-experiments/useExperiments'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { IconCheckInCircleFilled } from '@ds/icons/IconCheckInCircleFilled'
import { IconCircle } from '@ds/icons/IconCircle'

import translations from './ProductComparison.translations'

const { isComparisonAddDisabled } = defineProps<{
  isComparisonAddDisabled: boolean
  isResponsiveComparisonEnabled: boolean
  isChecked: boolean
}>()

const i18n = useI18n()
const experiments = useExperiments()
const isExperimentActive = experiments['experiment.comparisonPlp'] === 'with'

const emit = defineEmits(['change'])

function handleClick() {
  emit('change')
}
</script>
